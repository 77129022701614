<template>
    <BaseCard
        v-if="entry.windexTitle && entry.windexUrl"
        :entry="entry"
        :title="truncate(entry.windexTitle, 75)"
        :uri="agendaUrl"
        :is-inline="isInline"
        :data-product-type="entry.windexProductCategory"
        class="agenda-card"
    >
        <template #meta>
            <FenexTag v-if="entry.isFenex" />

            <div
                v-if="location"
                class="agenda-card__location"
            >
                <BaseIcon
                    icon="location"
                    class="agenda-card__icon"
                />
                <span class="agenda-card__location__text">{{ location }}</span>
            </div>

            <!-- <div
                v-if="time"
                class="agenda-card__time"
            >
                <BaseIcon
                    icon="clock"
                    class="agenda-card__icon"
                />
                {{ time }}
            </div> -->
        </template>

        <template
            v-if="date"
            #image
        >
            <div class="agenda-card__date">
                <div class="agenda-card__day">{{ day }}</div>
                <div class="agenda-card__month">{{ formattedMonth }}</div>
            </div>
        </template>
    </BaseCard>
</template>

<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import truncate from '@/utils/truncate';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        default: false
    }
});

const location = computed(() => {
    if (!props.entry?.windexLocation) {
        return;
    }

    return props.entry?.windexLocation.toLowerCase();
});

const date = computed(() => {
    return props.entry?.windexDate ?? null;
});

const day = computed(() => {
    if (!toValue(date)) {
        return;
    }

    return format(new Date(toValue(date)), 'dd', { locale: nl });
});

const formattedMonth = computed(() => {
    if (!toValue(date)) {
        return;
    }

    const formattedMonthName = format(new Date(toValue(date)), 'MMM', {
        locale: nl
    });

    return formattedMonthName.replace('.', '');
});

// const time = computed(() => {
//     if (!toValue(date)) {
//         return;
//     }

//     const formattedTime = format(new Date(toValue(date)), 'HH:mm', { locale: nl });

//     if (formattedTime === '00:00') {
//         return;
//     }

//     return formattedTime;
// });

const agendaUrl = `/bijeenkomsten/${props.entry.slug}/${props.entry.id}`;
</script>

<style lang="less" src="./AgendaCard.less" />
